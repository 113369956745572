import api from '../api';
import { unauthorizedApiResponseInterceptor } from '../api/interceptor';
import { AuthService } from '../api/services/auth';
import { State } from './interfaces';
import { logoutAction } from './reducers/authUser';
import { clearDataAction } from './reducers/common';

const initialize = (store: any) => {
  const state: State = store.getState();
  unauthorizedApiResponseInterceptor(() => {
    store.dispatch(logoutAction());
    store.dispatch(clearDataAction());
    api.setToken(undefined);
    window.location.reload();
  }, [AuthService.loginUrl]);

  api.setToken(state.authUser.token);
  if (state.authUser.token) {
    api.setToken(state.authUser.token);
    // store.dispatch(userActions.fetchProfile());
  }
  if (state.branchState?.currentBanchId) {
    api.setBranchId(state.branchState?.currentBanchId);
    // store.dispatch(userActions.fetchProfile());
  }
};

export default initialize;
