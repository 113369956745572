import React from 'react';
import { withRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';

import Routes from './routes/Routes';

import '../assets/sass/common.scss';
import { useMasterData } from 'hooks/useMasterData';
import api from 'api';
import FullPageLoader from 'components/loader/full-page-loader';

const App: React.FC = () => {
  // const masterData = useMasterData(api.jwellery);

  return (
    <div>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-center"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar={false}
      />
      {/* {(!masterData.loaded || masterData.loading) && <FullPageLoader />} */}
      {<Routes />}
    </div>
  );
};

// without using withRouter, route changes only reflect in url but not on page.
export default withRouter(App);
