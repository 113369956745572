import { IJwelleryItemData, IJwelItemState } from 'models/jwellery-item';
import {
  IJwellerySubTypeData,
  IJwelSubTypeState,
} from 'models/jwellery-subtype';
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
} from '../utils';

const JWEL_GET_SUB_TYPE_DATA_REQUEST = 'JWEL/GET_SUB_TYPE_DATA_REQUEST';
const JWEL_GET_SUB_TYPE_DATA_RESET = 'JWEL/GET_SUB_TYPE_DATA_RESET';
const JWEL_GET_SUB_TYPE_DATA_LOADED = 'JWEL/GET_SUB_TYPE_DATA_LOADED';

type GetJwellerySubTypeListRequestActionType = IAction<
  typeof JWEL_GET_SUB_TYPE_DATA_REQUEST
>;
type GetJwellerySubTypeListResetActionType = IAction<
  typeof JWEL_GET_SUB_TYPE_DATA_RESET
>;

type GetJwellerySubTypeListLoadedActionType = IActionWithPayload<
  typeof JWEL_GET_SUB_TYPE_DATA_LOADED,
  IJwellerySubTypeData[]
>;

type Actions =
  | GetJwellerySubTypeListLoadedActionType
  | GetJwellerySubTypeListRequestActionType
  | GetJwellerySubTypeListResetActionType;

export const getJwellerySubTypeListLoadedAction = (
  payload: IJwellerySubTypeData[]
): GetJwellerySubTypeListLoadedActionType => {
  return createActionWithPayload(JWEL_GET_SUB_TYPE_DATA_LOADED, payload);
};
export const getJwellerySubTypeListRequestAction = (): GetJwellerySubTypeListRequestActionType => {
  return createAction(JWEL_GET_SUB_TYPE_DATA_REQUEST);
};
export const getJwellerySubTypeListResetAction = (): GetJwellerySubTypeListResetActionType => {
  return createAction(JWEL_GET_SUB_TYPE_DATA_RESET);
};
const initialState: IJwelSubTypeState = {
  data: [],
  loading: false,
  loaded: false,
};

export const jwellerySubTypeReducer = (
  state: IJwelSubTypeState = initialState,
  action: Actions
): IJwelSubTypeState => {
  switch (action.type) {
    // reset
    case JWEL_GET_SUB_TYPE_DATA_RESET:
      return {
        data: [],
        loading: false,
        loaded: false,
      };
    // loading
    case JWEL_GET_SUB_TYPE_DATA_REQUEST:
      return {
        data: [],
        loading: true,
        loaded: false,
      };
    // loaded
    case JWEL_GET_SUB_TYPE_DATA_LOADED:
      return {
        data: action.payload,
        loading: false,
        loaded: true,
      };

    default:
      return state;
  }
};
