import axios, { AxiosRequestConfig } from 'axios';
import ApiServiceDataStore from './data';
import { saveAs } from 'file-saver';

export function getAuthHeader(token?: string) {
  if (!token) {
    return {};
  }
  return {
    Authorization: `Bearer ${token}`,
  };
}

export async function get<T>(url: string, store?: ApiServiceDataStore) {
  const options = {
    headers: {
      ...getAuthHeader(store?.token),
    },
  };
  const actUrl = store?.branchId
    ? `${url}?organisationId=${store?.branchId}`
    : url;

  return axios.get<T>(`${actUrl}`, options);
}

export async function post<T = any, U = any>(
  url: string,
  data: U,
  store?: ApiServiceDataStore
) {
  const options: AxiosRequestConfig = {
    data,
    url,
    method: 'POST',
    headers: {
      ...getAuthHeader(store?.token),
      'Content-Type': 'application/json',
    },
  };
  const actUrl = store?.branchId
    ? `${url}?organisationId=${store?.branchId}`
    : url;
  return axios.post<T>(`${actUrl}`, JSON.stringify(data), options);
}

export async function put<T = any, U = any>(
  url: string,
  data: U,
  store?: ApiServiceDataStore
) {
  const options: AxiosRequestConfig = {
    headers: {
      ...getAuthHeader(store?.token),
      'Content-Type': 'application/json',
    },
  };
  const actUrl = store?.branchId
    ? `${url}?organisationId=${store?.branchId}`
    : url;
  return axios.put<T>(`${actUrl}`, JSON.stringify(data), options);
}

export async function remove<T>(url: string, store?: ApiServiceDataStore) {
  const options: AxiosRequestConfig = {
    headers: {
      ...getAuthHeader(store?.token),
    },
  };
  const actUrl = store?.branchId
    ? `${url}?organisationId=${store?.branchId}`
    : url;
  return axios.delete<T>(`${actUrl}`, options);
}

export async function upload<T>(
  url: string,
  data: FormData,
  onUploadProgress: (progressEvent: any) => void,
  store?: ApiServiceDataStore
) {
  const options: AxiosRequestConfig = {
    onUploadProgress,
    headers: {
      ...getAuthHeader(store?.token),
      'Content-Type': 'multipart/form-data',
    },
  };
  const actUrl = store?.branchId
    ? `${url}?organisationId=${store?.branchId}`
    : url;
  return axios.post<T>(`${actUrl}`, data, options);
}

export async function download(
  url: string,
  store?: ApiServiceDataStore,
  accept?: string
) {
  const options: AxiosRequestConfig = {
    headers: {
      ...getAuthHeader(store?.token),
      Accept: accept,
    },
    responseType: 'arraybuffer',
  };
  const actUrl = store?.branchId
    ? `${url}?organisationId=${store?.branchId}`
    : url;
  return axios.get(`${actUrl}`, options);
}

export async function downloadPostData<T>(
  url: string,
  data: T,
  store?: ApiServiceDataStore,
  accept?: string
) {
  const options: AxiosRequestConfig = {
    headers: {
      ...getAuthHeader(store?.token),
      Accept: accept,
    },
    responseType: 'arraybuffer',
  };
  const actUrl = store?.branchId
    ? `${url}?organisationId=${store?.branchId}`
    : url;
  return axios.post(`${actUrl}`, data, options);
}

export const saveDownloadedFile = (
  buffer: any,
  type: string,
  fileName: string
) => {
  const data = new Blob([buffer], {
    type,
  });
  saveAs(data, fileName);
};
