import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { State } from '../store/interfaces';

import { StatusCodes } from 'http-status-codes';
import api from '../api';
import {
  getJwelleryItemListLoadedAction,
  getJwelleryItemListRequestAction,
} from 'store/reducers/jwellery-item';
import {
  getJwellerySubTypeListLoadedAction,
  getJwellerySubTypeListRequestAction,
} from 'store/reducers/jwellery-subtype';
import {
  getJwelleryOrnamentTypeListLoadedAction,
  getJwelleryOrnamentTypeListRequestAction,
} from 'store/reducers/jwellery-ornament-type';
import {
  getJwelleryCollectionSubTypeListLoadedAction,
  getJwelleryCollectionSubTypeListRequestAction,
} from 'store/reducers/collection-subtype';
import {
  getJwelleryCollectionOrnamentTypeListLoadedAction,
  getJwelleryCollectionOrnamentTypeListRequestAction,
} from 'store/reducers/collection-ornament-type';
import {
  getJwelleryCollectionModelsListLoadedAction,
  getJwelleryCollectionModelsListRequestAction,
} from 'store/reducers/jwellery-collection-models';
import {
  getJwelleryOrnamentModelsListLoadedAction,
  getJwelleryOrnamentModelsListRequestAction,
} from 'store/reducers/jwellery-ornament-models';
import {
  getJwelleryDetailListLoadedAction,
  getJwelleryDetailListRequestAction,
} from 'store/reducers/jwellery-detail';
import {
  getBranchListLoadedAction,
  getBranchListRequestAction,
} from 'store/reducers/branch';

export const useJwellery = () => {
  const jwellerySubTypeState = useSelector(
    (state: State) => state.jwellerySubTypeState
  );
  const jwelleryOrnamentTypeState = useSelector(
    (state: State) => state.jwelleryOrnamentTypeState
  );
  const jwelleryCollectionSubTypeState = useSelector(
    (state: State) => state.jwelleryCollectionSubTypeState
  );
  const jwelleryCollectionOrnamentTypeState = useSelector(
    (state: State) => state.jwelleryCollectionOrnamentTypeState
  );
  const jwelleryCollectionModelsState = useSelector(
    (state: State) => state.jwelleryCollectionModelsState
  );
  const jwelleryOrnamentModelsState = useSelector(
    (state: State) => state.jwelleryOrnamentModelsState
  );
  const jwelleryDetailState = useSelector(
    (state: State) => state.jwelleryDetailState
  );
  const branchState = useSelector((state: State) => state.branchState);
  const dispatch = useDispatch();
  const [dataError, setDataError] = useState<string | undefined>();
  const [updating, setUpdating] = useState<boolean>(false);
  const history = useHistory();

  const getBranchData = async (onSuccess?: () => void) => {
    setDataError(undefined);
    setUpdating(true);
    dispatch(getBranchListRequestAction());
    try {
      const response = await api.jwellery.getBranchData();
      if (response.status === StatusCodes.OK) {
        dispatch(getBranchListLoadedAction(response.data));
        if (onSuccess) onSuccess();
      } else {
        // setDataError('Invalid Username or Password!');
      }
    } catch (error) {
      // setDataError('Invalid Username or Password!');
    }
    setUpdating(false);
  };

  const getJwelleryItemData = async (onSuccess?: () => void) => {
    setDataError(undefined);
    setUpdating(true);
    dispatch(getJwelleryItemListRequestAction());
    try {
      const response = await api.jwellery.getJewelItemData();
      if (response.status === StatusCodes.OK) {
        dispatch(getJwelleryItemListLoadedAction(response.data));
        if (onSuccess) onSuccess();
      } else {
        // setDataError('Invalid Username or Password!');
      }
    } catch (error) {
      // setDataError('Invalid Username or Password!');
    }
    setUpdating(false);
  };

  const getJwellerySubTypeData = async (
    itemID: number,
    onSuccess?: () => void
  ) => {
    setDataError(undefined);
    setUpdating(true);
    dispatch(getJwellerySubTypeListRequestAction());
    try {
      const response = await api.jwellery.getJewelSubTypeData(itemID);
      if (response.status === StatusCodes.OK) {
        dispatch(getJwellerySubTypeListLoadedAction(response.data));
        if (onSuccess) onSuccess();
      } else {
        // setDataError('Invalid Username or Password!');
      }
    } catch (error) {
      // setDataError('Invalid Username or Password!');
    }
    setUpdating(false);
  };

  const getJwelleryOrnamentTypeData = async (
    itemID: number,
    subTypeID: number,
    onSuccess?: () => void
  ) => {
    setDataError(undefined);
    setUpdating(true);
    dispatch(getJwelleryOrnamentTypeListRequestAction());
    try {
      const response = await api.jwellery.getJewelOrnamentTypeData(
        itemID,
        subTypeID
      );
      if (response.status === StatusCodes.OK) {
        dispatch(getJwelleryOrnamentTypeListLoadedAction(response.data));
        if (onSuccess) onSuccess();
      } else {
        // setDataError('Invalid Username or Password!');
      }
    } catch (error) {
      // setDataError('Invalid Username or Password!');
    }
    setUpdating(false);
  };

  const getJwelleryCollectionSubTypeData = async (
    collectionID: number,
    onSuccess?: () => void
  ) => {
    setDataError(undefined);
    setUpdating(true);
    dispatch(getJwelleryCollectionSubTypeListRequestAction());
    try {
      const response = await api.jwellery.getJewelCollectionSubTypeData(
        collectionID
      );
      if (response.status === StatusCodes.OK) {
        dispatch(getJwelleryCollectionSubTypeListLoadedAction(response.data));
        if (onSuccess) onSuccess();
      } else {
        // setDataError('Invalid Username or Password!');
      }
    } catch (error) {
      // setDataError('Invalid Username or Password!');
    }
    setUpdating(false);
  };

  const getJwelleryCollectionOrnamentTypeData = async (
    collectionID: number,
    subTypeID: number,
    onSuccess?: () => void
  ) => {
    setDataError(undefined);
    setUpdating(true);
    dispatch(getJwelleryCollectionOrnamentTypeListRequestAction());
    try {
      const response = await api.jwellery.getJewelCollectionOrnamentTypeData(
        collectionID,
        subTypeID
      );
      if (response.status === StatusCodes.OK) {
        dispatch(
          getJwelleryCollectionOrnamentTypeListLoadedAction(response.data)
        );
        if (onSuccess) onSuccess();
      } else {
        // setDataError('Invalid Username or Password!');
      }
    } catch (error) {
      // setDataError('Invalid Username or Password!');
    }
    setUpdating(false);
  };
  const getJwelleryCollectionModelsData = async (
    collectionID: number,
    subTypeID: number,
    ornamentTypeId: number,
    onSuccess?: () => void
  ) => {
    setDataError(undefined);
    setUpdating(true);
    dispatch(getJwelleryCollectionModelsListRequestAction());
    try {
      const response = await api.jwellery.getJewelCollectionModelsData(
        collectionID,
        subTypeID,
        ornamentTypeId
      );
      if (response.status === StatusCodes.OK) {
        dispatch(getJwelleryCollectionModelsListLoadedAction(response.data));
        if (onSuccess) onSuccess();
      } else {
        // setDataError('Invalid Username or Password!');
      }
    } catch (error) {
      // setDataError('Invalid Username or Password!');
    }
    setUpdating(false);
  };
  const getJwelleryOrnamentModelsData = async (
    itemID: number,
    subTypeID: number,
    ornamentTypeId: number,
    onSuccess?: () => void
  ) => {
    setDataError(undefined);
    setUpdating(true);
    dispatch(getJwelleryOrnamentModelsListRequestAction());
    try {
      const response = await api.jwellery.getJewelOrnamentModelsData(
        itemID,
        subTypeID,
        ornamentTypeId
      );
      if (response.status === StatusCodes.OK) {
        dispatch(getJwelleryOrnamentModelsListLoadedAction(response.data));
        if (onSuccess) onSuccess();
      } else {
        // setDataError('Invalid Username or Password!');
      }
    } catch (error) {
      // setDataError('Invalid Username or Password!');
    }
    setUpdating(false);
  };
  const getJwelleryDetailData = async (
    detailID: number,
    onSuccess?: () => void
  ) => {
    setDataError(undefined);
    setUpdating(true);
    dispatch(getJwelleryDetailListRequestAction());
    try {
      const response = await api.jwellery.getJewelDetailData(detailID);
      if (response.status === StatusCodes.OK) {
        dispatch(getJwelleryDetailListLoadedAction(response.data));
        if (onSuccess) onSuccess();
      } else {
        // setDataError('Invalid Username or Password!');
      }
    } catch (error) {
      // setDataError('Invalid Username or Password!');
    }
    setUpdating(false);
  };
  return {
    isBranchSelected: !!branchState.currentBanchId,
    jwellerySubTypeState,
    getJwellerySubTypeData,
    jwelleryOrnamentTypeState,
    getJwelleryOrnamentTypeData,
    jwelleryCollectionSubTypeState,
    getJwelleryCollectionSubTypeData,
    jwelleryCollectionOrnamentTypeState,
    getJwelleryCollectionOrnamentTypeData,
    jwelleryCollectionModelsState,
    getJwelleryCollectionModelsData,
    jwelleryOrnamentModelsState,
    getJwelleryOrnamentModelsData,
    jwelleryDetailState,
    getJwelleryDetailData,
    getBranchData,
    getJwelleryItemData,
    updating,
  };
};
