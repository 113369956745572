import {
  IJwelCollectionModelsState,
  IJwelleryCollectionModelsData,
} from 'models/jwellery-collection-models';
import {
  IJwelleryOrnamentTypeData,
  IJwelOrnamentTypeState,
} from 'models/jwellery-ornament-type';
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
} from '../utils';

const JWEL_GET_COLLECTION_MODELS_DATA_REQUEST =
  'JWEL/GET_COLLECTION_MODELS_DATA_REQUEST';
const JWEL_GET_COLLECTION_MODELS_DATA_RESET =
  'JWEL/GET_COLLECTION_MODELS_DATA_RESET';
const JWEL_GET_COLLECTION_MODELS_DATA_LOADED =
  'JWEL/GET_COLLECTION_MODELS_DATA_LOADED';

type GetJwelleryCollectionModelsListRequestActionType = IAction<
  typeof JWEL_GET_COLLECTION_MODELS_DATA_REQUEST
>;
type GetJwelleryCollectionModelsListResetActionType = IAction<
  typeof JWEL_GET_COLLECTION_MODELS_DATA_RESET
>;

type GetJwelleryCollectionModelsListLoadedActionType = IActionWithPayload<
  typeof JWEL_GET_COLLECTION_MODELS_DATA_LOADED,
  IJwelleryCollectionModelsData[]
>;

type Actions =
  | GetJwelleryCollectionModelsListLoadedActionType
  | GetJwelleryCollectionModelsListRequestActionType
  | GetJwelleryCollectionModelsListResetActionType;

export const getJwelleryCollectionModelsListLoadedAction = (
  payload: IJwelleryCollectionModelsData[]
): GetJwelleryCollectionModelsListLoadedActionType => {
  return createActionWithPayload(
    JWEL_GET_COLLECTION_MODELS_DATA_LOADED,
    payload
  );
};
export const getJwelleryCollectionModelsListRequestAction = (): GetJwelleryCollectionModelsListRequestActionType => {
  return createAction(JWEL_GET_COLLECTION_MODELS_DATA_REQUEST);
};
export const getJwelleryCollectionModelsListResetAction = (): GetJwelleryCollectionModelsListResetActionType => {
  return createAction(JWEL_GET_COLLECTION_MODELS_DATA_RESET);
};
const initialState: IJwelCollectionModelsState = {
  data: [],
  loading: false,
  loaded: false,
};

export const jwelleryCollectionModelsReducer = (
  state: IJwelCollectionModelsState = initialState,
  action: Actions
): IJwelCollectionModelsState => {
  switch (action.type) {
    // reset
    case JWEL_GET_COLLECTION_MODELS_DATA_RESET:
      return {
        data: [],
        loading: false,
        loaded: false,
      };
    // loading
    case JWEL_GET_COLLECTION_MODELS_DATA_REQUEST:
      return {
        data: [],
        loading: true,
        loaded: false,
      };
    // loaded
    case JWEL_GET_COLLECTION_MODELS_DATA_LOADED:
      return {
        data: action.payload,
        loading: false,
        loaded: true,
      };

    default:
      return state;
  }
};
