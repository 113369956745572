import axios from 'axios';
import API_DOMAIN from './apiDomain';

export const unauthorizedApiResponseInterceptor = (
  onUnauthorizedResponse?: () => void,
  excludedEndpoints?: string[]
) => {
  axios.interceptors.response.use(undefined, (err) => {
    if (err.response && err.response.status === 401) {
      if (!excludeOn(err, excludedEndpoints) && onUnauthorizedResponse) {
        onUnauthorizedResponse();
      }
    }
    throw err;
  });
};

export const excludeOn = (err: any, list?: string[]) => {
  if (!list?.length) {
    return false;
  }
  const url = new URL(
    err.response.config.url.replace(API_DOMAIN, ''),
    API_DOMAIN
  );
  return list.includes(url.pathname);
};

export default unauthorizedApiResponseInterceptor;
