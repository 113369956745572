import { IBranchItemData } from 'models/branch';
import { IJwelleryCollectionModelsData } from 'models/jwellery-collection-models';
import { IJwelleryDetailData } from 'models/jwellery-detail';
import { IJwelleryItemData } from 'models/jwellery-item';
import { IJwelleryOrnamentTypeData } from 'models/jwellery-ornament-type';
import { IJwellerySubTypeData } from 'models/jwellery-subtype';
import { ApiResponse, TokenData } from '../models';
import * as http from './http';
import ApiService from './service';
// http://localhost:8000/api/item?organisationId=17

export class JwelleryService extends ApiService {
  public async getBranchData() {
    const url = `${this.apiDomain}/api/branch/`;
    const response = await http.get<ApiResponse<IBranchItemData[]>>(url);
    return response.data;
  }

  public async getJewelItemData() {
    const url = `${this.apiDomain}/api/item`;
    const response = await http.get<ApiResponse<IJwelleryItemData[]>>(
      url,
      this.store
    );
    return response.data;
  }
  public async getJewelSubTypeData(itemID: number) {
    const url = `${this.apiDomain}/api/item/${itemID}/sub-type`;
    const response = await http.get<ApiResponse<IJwellerySubTypeData[]>>(
      url,
      this.store
    );
    return response.data;
  }
  public async getJewelOrnamentTypeData(itemID: number, subTypeID: number) {
    const url = `${this.apiDomain}/api/item/${itemID}/sub-type/${subTypeID}/ornament-type`;
    const response = await http.get<ApiResponse<IJwelleryOrnamentTypeData[]>>(
      url,
      this.store
    );
    return response.data;
  }
  public async getJewelCollectionSubTypeData(collectionID: number) {
    const url = `${this.apiDomain}/api/collection/${collectionID}/sub-type`;
    const response = await http.get<ApiResponse<IJwellerySubTypeData[]>>(
      url,
      this.store
    );
    return response.data;
  }
  public async getJewelCollectionOrnamentTypeData(
    collectionID: number,
    subTypeID: number
  ) {
    const url = `${this.apiDomain}/api/collection/${collectionID}/sub-type/${subTypeID}/ornament-type`;
    const response = await http.get<ApiResponse<IJwelleryOrnamentTypeData[]>>(
      url,
      this.store
    );
    return response.data;
  }
  public async getJewelCollectionModelsData(
    collectionID: number,
    subTypeID: number,
    ornamentTypeId: number
  ) {
    // http://localhost:8000/api/collection/3/sub-type/3/ornament-type/1/models

    const url = `${this.apiDomain}/api/collection/${collectionID}/sub-type/${subTypeID}/ornament-type/${ornamentTypeId}/models`;
    const response = await http.get<
      ApiResponse<IJwelleryCollectionModelsData[]>
    >(url, this.store);
    return response.data;
  }
  public async getJewelOrnamentModelsData(
    itemID: number,
    subTypeID: number,
    ornamentTypeId: number
  ) {
    // http://localhost:8000/api/item/3/sub-type/3/ornament-type/1/models

    const url = `${this.apiDomain}/api/item/${itemID}/sub-type/${subTypeID}/ornament-type/${ornamentTypeId}/models`;
    const response = await http.get<
      ApiResponse<IJwelleryCollectionModelsData[]>
    >(url, this.store);
    return response.data;
  }
  public async getJewelDetailData(detailID: number) {
    const url = `${this.apiDomain}/api/design/${detailID}`;
    const response = await http.get<ApiResponse<IJwelleryDetailData[]>>(
      url,
      this.store
    );
    return response.data;
  }
}
