import {
  IJwelleryOrnamentTypeData,
  IJwelOrnamentTypeState,
} from 'models/jwellery-ornament-type';
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
} from '../utils';

const JWEL_GET_COLLECTION_ORNAMENT_TYPE_DATA_REQUEST =
  'JWEL/GET_COLLECTION_ORNAMENT_TYPE_DATA_REQUEST';
const JWEL_GET_COLLECTION_ORNAMENT_TYPE_DATA_RESET =
  'JWEL/GET_COLLECTION_ORNAMENT_TYPE_DATA_RESET';
const JWEL_GET_COLLECTION_ORNAMENT_TYPE_DATA_LOADED =
  'JWEL/GET_COLLECTION_ORNAMENT_TYPE_DATA_LOADED';

type GetJwelleryCollectionOrnamentTypeListRequestActionType = IAction<
  typeof JWEL_GET_COLLECTION_ORNAMENT_TYPE_DATA_REQUEST
>;
type GetJwelleryCollectionOrnamentTypeListResetActionType = IAction<
  typeof JWEL_GET_COLLECTION_ORNAMENT_TYPE_DATA_RESET
>;

type GetJwelleryCollectionOrnamentTypeListLoadedActionType = IActionWithPayload<
  typeof JWEL_GET_COLLECTION_ORNAMENT_TYPE_DATA_LOADED,
  IJwelleryOrnamentTypeData[]
>;

type Actions =
  | GetJwelleryCollectionOrnamentTypeListLoadedActionType
  | GetJwelleryCollectionOrnamentTypeListRequestActionType
  | GetJwelleryCollectionOrnamentTypeListResetActionType;

export const getJwelleryCollectionOrnamentTypeListLoadedAction = (
  payload: IJwelleryOrnamentTypeData[]
): GetJwelleryCollectionOrnamentTypeListLoadedActionType => {
  return createActionWithPayload(
    JWEL_GET_COLLECTION_ORNAMENT_TYPE_DATA_LOADED,
    payload
  );
};
export const getJwelleryCollectionOrnamentTypeListRequestAction = (): GetJwelleryCollectionOrnamentTypeListRequestActionType => {
  return createAction(JWEL_GET_COLLECTION_ORNAMENT_TYPE_DATA_REQUEST);
};
export const getJwelleryCollectionOrnamentTypeListResetAction = (): GetJwelleryCollectionOrnamentTypeListResetActionType => {
  return createAction(JWEL_GET_COLLECTION_ORNAMENT_TYPE_DATA_RESET);
};
const initialState: IJwelOrnamentTypeState = {
  data: [],
  loading: false,
  loaded: false,
};

export const jwelleryCollectionOrnamentTypeReducer = (
  state: IJwelOrnamentTypeState = initialState,
  action: Actions
): IJwelOrnamentTypeState => {
  switch (action.type) {
    // reset
    case JWEL_GET_COLLECTION_ORNAMENT_TYPE_DATA_RESET:
      return {
        data: [],
        loading: false,
        loaded: false,
      };
    // loading
    case JWEL_GET_COLLECTION_ORNAMENT_TYPE_DATA_REQUEST:
      return {
        data: [],
        loading: true,
        loaded: false,
      };
    // loaded
    case JWEL_GET_COLLECTION_ORNAMENT_TYPE_DATA_LOADED:
      return {
        data: action.payload,
        loading: false,
        loaded: true,
      };

    default:
      return state;
  }
};
