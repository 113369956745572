import { IJwelleryItemData, IJwelItemState } from 'models/jwellery-item';
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
} from '../utils';

const JWEL_GET_ITEM_DATA_REQUEST = 'JWEL/GET_ITEM_DATA_REQUEST';
const JWEL_GET_ITEM_DATA_LOADED = 'JWEL/GET_ITEM_DATA_LOADED';

type GetJwelleryItemListRequestActionType = IAction<
  typeof JWEL_GET_ITEM_DATA_REQUEST
>;

type GetJwelleryItemListLoadedActionType = IActionWithPayload<
  typeof JWEL_GET_ITEM_DATA_LOADED,
  IJwelleryItemData[]
>;

type Actions =
  | GetJwelleryItemListLoadedActionType
  | GetJwelleryItemListRequestActionType;

export const getJwelleryItemListLoadedAction = (
  payload: IJwelleryItemData[]
): GetJwelleryItemListLoadedActionType => {
  return createActionWithPayload(JWEL_GET_ITEM_DATA_LOADED, payload);
};
export const getJwelleryItemListRequestAction = (): GetJwelleryItemListRequestActionType => {
  return createAction(JWEL_GET_ITEM_DATA_REQUEST);
};

const initialState: IJwelItemState = {
  data: [],
  loading: false,
  loaded: false,
};

export const jwelleryItemReducer = (
  state: IJwelItemState = initialState,
  action: Actions
): IJwelItemState => {
  switch (action.type) {
    // loaded
    case JWEL_GET_ITEM_DATA_REQUEST:
      return {
        data: [],
        loading: true,
        loaded: false,
      };
    // loaded
    case JWEL_GET_ITEM_DATA_LOADED:
      return {
        data: action.payload,
        loading: false,
        loaded: true,
      };

    default:
      return state;
  }
};
