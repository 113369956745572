export enum RouteKeys {
  Login = '/auth/login',
  Home = '/',
  Type = '/type/:type/:typeId',
  SubType = '/subtype/:type/:subtype/:typeId/:subtypeId',
  ModelType = '/modeltype/:type/:modeltype/:typeId/:subtypeId/:modelId',
  Collections = '/collections',
  CollectionSubType = '/collections/:collection/:collectionId',
  CollectionOrnamentType = '/collections/:collection/:collectionId/:subTypeID',
  CollectionModelType = '/collections/:collection/:collectionId/:subTypeID/:ornamentTypeId',
  Branches = '/branches',
  NotFound = '/page-not-found',
}
