import { IBranchItemData, IBranchState } from 'models/branch';
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
} from '../utils';

const BRANCH_SET_CURRENT_BRANCH = 'BRANCH/SET_CURRENT_BRANCH';
const BRANCH_GET_DATA_REQUEST = 'BRANCH/GET_DATA_REQUEST';
const BRANCH_GET_DATA_LOADED = 'BRANCH/GET_DATA_LOADED';

type GetBranchListRequestActionType = IAction<typeof BRANCH_GET_DATA_REQUEST>;

type GetBranchListLoadedActionType = IActionWithPayload<
  typeof BRANCH_GET_DATA_LOADED,
  IBranchItemData[]
>;

type SetCurrentBranchActionType = IActionWithPayload<
  typeof BRANCH_SET_CURRENT_BRANCH,
  number
>;

type Actions =
  | SetCurrentBranchActionType
  | GetBranchListLoadedActionType
  | GetBranchListRequestActionType;

export const setCurrentBranchAction = (
  payload: number
): SetCurrentBranchActionType => {
  return createActionWithPayload(BRANCH_SET_CURRENT_BRANCH, payload);
};

export const getBranchListLoadedAction = (
  payload: IBranchItemData[]
): GetBranchListLoadedActionType => {
  return createActionWithPayload(BRANCH_GET_DATA_LOADED, payload);
};
export const getBranchListRequestAction = (): GetBranchListRequestActionType => {
  return createAction(BRANCH_GET_DATA_REQUEST);
};

const initialState: IBranchState = {
  branches: [],
  currentBanchId: undefined,
  loading: false,
  loaded: false,
};

export const branchReducer = (
  state: IBranchState = initialState,
  action: Actions
): IBranchState => {
  switch (action.type) {
    // loaded
    case BRANCH_GET_DATA_REQUEST:
      return {
        branches: [],
        currentBanchId: state.currentBanchId,
        loading: true,
        loaded: false,
      };
    // loaded
    case BRANCH_GET_DATA_LOADED:
      return {
        branches: action.payload,
        currentBanchId: state.currentBanchId,
        loading: false,
        loaded: true,
      };
    // set current branch
    case BRANCH_SET_CURRENT_BRANCH:
      return {
        branches: state.branches,
        currentBanchId: action.payload,
        loading: false,
        loaded: true,
      };
    default:
      return state;
  }
};
