import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';

import { authUserReducer } from './reducers/authUser';
import { State } from './interfaces';
import { jwelleryItemReducer } from './reducers/jwellery-item';
import { jwellerySubTypeReducer } from './reducers/jwellery-subtype';
import { jwelleryOrnamentTypeReducer } from './reducers/jwellery-ornament-type';
import { jwelleryCollectionSubTypeReducer } from './reducers/collection-subtype';
import { jwelleryCollectionOrnamentTypeReducer } from './reducers/collection-ornament-type';
import { jwelleryCollectionModelsReducer } from './reducers/jwellery-collection-models';
import { jwelleryOrnamentModelsReducer } from './reducers/jwellery-ornament-models';
import { jwelleryDetailReducer } from './reducers/jwellery-detail';
import { branchReducer } from './reducers/branch';

const rootReducer = combineReducers<State>({
  authUser: authUserReducer,
  toastr: toastrReducer,
  loadingBar: loadingBarReducer,
  jwelleryItemState: jwelleryItemReducer,
  jwellerySubTypeState: jwellerySubTypeReducer,
  jwelleryOrnamentTypeState: jwelleryOrnamentTypeReducer,
  jwelleryCollectionSubTypeState: jwelleryCollectionSubTypeReducer,
  jwelleryCollectionOrnamentTypeState: jwelleryCollectionOrnamentTypeReducer,
  jwelleryCollectionModelsState: jwelleryCollectionModelsReducer,
  jwelleryOrnamentModelsState: jwelleryOrnamentModelsReducer,
  jwelleryDetailState: jwelleryDetailReducer,
  branchState: branchReducer,
});

export default rootReducer;
