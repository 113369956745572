import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ContainerLoader } from '../../components/loader';
import BranchAuthenticateRoute from './BranchAuthenticateRoute';

import { RouteKeys } from './route-keys';

const Home = lazy(() => import('../home'));
const ItemSubType = lazy(() => import('../pages/ItemSubType'));
const ItemOrnamentType = lazy(() => import('../pages/ItemOrnamentType'));
const ItemModelType = lazy(() => import('../pages/ItemModelType'));
const Collections = lazy(() => import('../collections'));
const CollectionSubType = lazy(
  () => import('../collections/CollectionSubType')
);
const CollectionOrnamentType = lazy(
  () => import('../collections/CollectionOrnamentType')
);
const CollectionModelType = lazy(
  () => import('../collections/CollectionModelType')
);
const Branches = lazy(() => import('../home/Branches'));
const NotFound = lazy(() => import('../notfound'));

class Routes extends React.Component {
  render() {
    return (
      <Suspense fallback={<ContainerLoader height={500} text={'Loading...'} />}>
        <Switch>
          <BranchAuthenticateRoute
            exact
            path={RouteKeys.Home}
            component={Home}
          />
          <BranchAuthenticateRoute
            exact
            path={RouteKeys.Type}
            component={ItemSubType}
          />
          <BranchAuthenticateRoute
            exact
            path={RouteKeys.SubType}
            component={ItemOrnamentType}
          />
          <BranchAuthenticateRoute
            exact
            path={RouteKeys.ModelType}
            component={ItemModelType}
          />
          <BranchAuthenticateRoute
            exact
            path={RouteKeys.Collections}
            component={Collections}
          />
          <BranchAuthenticateRoute
            exact
            path={RouteKeys.CollectionSubType}
            component={CollectionSubType}
          />
          <BranchAuthenticateRoute
            exact
            path={RouteKeys.CollectionOrnamentType}
            component={CollectionOrnamentType}
          />
          <BranchAuthenticateRoute
            exact
            path={RouteKeys.CollectionModelType}
            component={CollectionModelType}
          />
          <Route exact path={RouteKeys.Branches} component={Branches} />
          <Route exact path={RouteKeys.NotFound} component={NotFound} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    );
  }
}

export default Routes;
